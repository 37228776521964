import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { LogoNegative } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export default memo(function Error404() {
  return (
    <Container>
      <Wrapper>
        <Logo>
          <LogoNegative />
        </Logo>
        <Title>Page Not Found</Title>
        <Text>Oops, we couldn't find this page!</Text>
        <CTA
          label={useVocabularyData('back-to-home', 'en-US')}
          URL="/"
          variant="W"
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  position: fixed;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    width: 90%;
  }
`

const Logo = styled.div`
  height: 10.75rem;
  margin-bottom: 3rem;

  svg {
    width: auto;
    height: 100%;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const Title = styled.h1`
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  text-transform: uppercase;
`

const Text = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-top: 1rem;
`

const CTA = styled(Button)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  margin-top: 3.125rem;
`
